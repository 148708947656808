// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-busca-js": () => import("./../../../src/pages/busca.js" /* webpackChunkName: "component---src-pages-busca-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-templates-blog-list-index-js": () => import("./../../../src/templates/blog-list/index.js" /* webpackChunkName: "component---src-templates-blog-list-index-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../../../src/templates/blog-post/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */)
}

